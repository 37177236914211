// import React from "react";
import React, { useEffect, useState, useMemo } from "react";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { useDispatch } from "react-redux";
import * as actions from "../../_redux/customers/customersActions";
import { useDropzone } from "react-dropzone";
/*★★★★★ */
const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
  }));
  const title1 = "顔認証類似度設定"
  const title2 = "ロゴ設定"

// drop-zone definition
const IMAGE_WIDTH  = 130
const IMAGE_HEIGHT = IMAGE_WIDTH
const SELECT_BTN_WIDTH  = 101
const SELECT_BTN_HEIGHT = 27
const SELECT_BTN_PADDING = (IMAGE_WIDTH - SELECT_BTN_WIDTH) / 2
const PADDING_LEFT = '0px 0px 0px ' + SELECT_BTN_PADDING + 'px'

const thumbsContainer = {
display: 'flex',
flexDirection: 'row',
flexWrap: 'wrap',
borderStyle: 'solid',
borderWidth: 1,
borderColor: '#E4E6EF',
width: IMAGE_WIDTH,
height: IMAGE_HEIGHT,
marginTop: 1,
marginBottom: 8
};
const thumb = {
display: 'inline-flex',
};
const thumbInner = {
display: 'flex',
maxWidth: IMAGE_WIDTH,
maxHeight: IMAGE_HEIGHT,
overflow: 'hidden'
};

const baseStyle = {
flex: 1,
display: 'flex',
flexDirection: 'column',
alignItems: 'center',
padding: '4px',
width: SELECT_BTN_WIDTH,
height: SELECT_BTN_HEIGHT,
borderRadius: 5,
backgroundColor: '#3699FF',
color: '#ffffff',
outline: 'none',
transition: 'border .24s ease-in-out'
};

const fileSelect = {
padding: PADDING_LEFT,
};

const activeStyle = {
borderColor: '#e4e6ef',
backgroundColor: '#00ffff',
};

const acceptStyle = {
borderColor: '#e4e6ef'
};
  
const rejectStyle = {
borderColor: '#e4e6ef'
};

const saveStyle = {
    //alignItems: 'center',
    padding: '4px',
    width: 80,
    height: SELECT_BTN_HEIGHT,
    borderRadius: 5,
    backgroundColor: '#3699FF',
    color: '#ffffff',
    outline: 'none',
    //transition: 'border .24s ease-in-out'
    };

export function Settings({ initval },
  ) {
    // console.debug(initval);
    // let initThreshold = "89.0"
    const [resultView, settresultView] = useState("");
    const [thresholdResult, setthresholdResult] = useState("");
    const [cnt, setCnt] = useState(0);
    useEffect(() => {
        // console.debug(cnt);
        if( thresholdResult == "OK") {
            settresultView("Setting OK");
        } else if ( thresholdResult == "NG") {
            settresultView("Setting NG");
        } else {
            settresultView("");
        }
     }, [cnt]);

    const dispatch = useDispatch();
    const saveValue = (threshold) => {
        if (threshold) {
            dispatch(actions.setThreshold(threshold)).then((res) => {
                // console.debug(res.result)
                if( res.result == "OK") {
                    // console.debug("R1")
                    setthresholdResult("OK");
                } else {
                    // console.debug("R2")
                    setthresholdResult("NG");
                }
                setCnt(cnt+1);
            });
        }
    };
      
    const classes = useStyles();
    const [values, setValues] = React.useState({
        val: initval,
      });
    const handleChange = val => event => {
        // console.debug('handleChange')
        setValues({ ...values, [val]: event.target.value });
        settresultView("");
    };
    const saveSubmit = () => {
        // console.debug(values)
        saveValue(values.val);
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isDragActive,
        isDragReject,
        isDragAccept
      ]);
    
    
    useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const [files, setFiles] = useState([]);
    const [binary, setBinary] = useState([]);
    const {getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive} = useDropzone({
      accept: "image/jpeg", // "image/*" , image/png;
      multiple: false,
      onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
        acceptedFiles.forEach((file) => {
          const reader = new FileReader()
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
            setBinary(reader.result)
          }
          reader.readAsArrayBuffer(file)
        })
      }
    });

    const saveSubmitLogo = () => {
        // console.debug(values)
        saveLogo(binary);
    };

    const saveLogo = (binary) => {
        if (binary) {
            dispatch(actions.setLogo(binary)).then((res) => {
                // console.debug(res.result)
                if( res.result == "OK") {
                    // console.debug("R1")
                    // setthresholdResult("OK");
                } else {
                    // console.debug("R2")
                    // setthresholdResult("NG");
                }
//                setCnt(cnt+1);
            });
        }
    };
      
    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            <img
              src={file.preview}
              class="img"
            />
          </div>
        </div>
      ));
         
    return (
        <div className="row">
            <div className="col-md-6">
                <Card>
                    <CardHeader title={title1}/>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-11">
                                <TextField
                                    id="outlined-name"
                                    label="Threshold"
                                    className={classes.textField}
                                    value={values.val}
                                    onChange={handleChange('val')}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-11 ml-4">
                                <div className="font-size-xs text-success">{resultView}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-11 mt-3">
                                <div className="row  justify-content-end">
                                    <button
                                        type="submit"
                                        onClick={() => saveSubmit()}
                                        className="btn btn-primary btn-elevate"
                                        style={saveStyle}
                                        >
                                        保存
                                    </button>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
            <div className="col-md-6">
                <Card>
                    <CardHeader title={title2}/>
                    <CardBody>
                        {/* <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-auto mx-auto">
                                        <div style={thumbsContainer}>
                                            {thumbs}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-auto mx-auto">
                                            <div {...getRootProps({style})}>
                                            <input {...getInputProps()} />
                                            {
                                                <p>ファイル選択</p>
                                            }
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="col-md-11">
                                    <div className="row justify-content-end" style={{marginTop: 140}}>
                                        <button
                                            type="submit"
                                            onClick={() => saveSubmitLogo()}
                                            className="btn btn-primary"
                                            style={saveStyle}
                                            >
                                            保存
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </CardBody>
                </Card>
            </div>
        </div>

    );
  }
  