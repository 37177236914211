// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState, useMemo } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Textarea,
  // Select,
  // DatePickerField,
} from "../../../../../../_metronic/_partials/controls";
import { useDropzone } from "react-dropzone";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import MInput from '@material-ui/core/Input';
import MSelect from '@material-ui/core/Select';
import  "./img.css"

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  company: Yup.string()
    .min(1, "Minimum 1 symbols")
    .max(50, "Maximum 50 symbols")
    .required("入力必須です"),
  id: Yup.string()
    .min(1, "Minimum 1 symbols")
    .max(50, "Maximum 50 symbols")
    .required("入力必須です"),
  first_name: Yup.string()
    .min(1, "Minimum 1 symbols")
    .max(50, "Maximum 50 symbols")
//    .required("Firstname is required"),
    .required("入力必須です"),
  last_name: Yup.string()
    .min(1, "Minimum 1 symbols")
    .max(50, "Maximum 50 symbols")
//    .required("Lastname is required"),
    .required("入力必須です"),
});

// drop-zone definition
const IMAGE_WIDTH  = 130
const IMAGE_HEIGHT = IMAGE_WIDTH * 1.33
const SELECT_BTN_WIDTH  = 101
const SELECT_BTN_HEIGHT = 27
const SELECT_BTN_PADDING = (IMAGE_WIDTH - SELECT_BTN_WIDTH) / 2
const PADDING_LEFT = '0px 0px 0px ' + SELECT_BTN_PADDING + 'px'

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: '#E4E6EF',
  width: IMAGE_WIDTH,
  height: IMAGE_HEIGHT,
  marginTop: 1,
  marginBottom: 8
};
const thumb = {
  display: 'inline-flex',
};
const thumbInner = {
  display: 'flex',
  maxWidth: IMAGE_WIDTH,
  maxHeight: IMAGE_HEIGHT,
  overflow: 'hidden'
};
// const img = {
//   display: 'block',
//   width: IMAGE_WIDTH,
//   height: 'auto'
// };

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const names = [
  "MST-200VDR",
  "MST-700VD", 
  "MST-800VD", 
  "MST-1000VDR",
  "MST-1500VD",
  "MST-1500VDR",
  "MST-2200VD",
  "MST-2200VDR",
  "MST-600VDL",
  "MST-1500VDL",
  ];

    function getStyles(name, machineName, theme) {
    return {
      fontWeight:
        machineName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      marginLeft: 5,
        },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4px',
    width: SELECT_BTN_WIDTH,
    height: SELECT_BTN_HEIGHT,
    borderRadius: 5,
    backgroundColor: '#3699FF',
    color: '#ffffff',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };

  const fileSelect = {
    padding: PADDING_LEFT,
  };

  const activeStyle = {
    borderColor: '#e4e6ef',
    backgroundColor: '#00ffff',
  };
  
  const acceptStyle = {
    borderColor: '#e4e6ef'
  };
  
  const rejectStyle = {
    borderColor: '#e4e6ef'
  };

  const chipStyle = {
    marginBottom: 8,
    marginLeft: 10,
  }

  const licenseStyle = {
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    borderColor: '#e4e6ef',
    borderStyle: 'solid'
  }
  
  const cancelButtonStyle = {
    padding: 1,
    width: 101,
    height: 27,
    marginRight: 6,
    fontSize: 9,
    borderColor: '#e4e6ef' 
  }
  const submitButtonStyle = {
    padding: 1,
    width: 101,
    height: 27
  }

export function CustomerEditFormNew({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  history,
}) {
  // console.debug(customer)

  // drop-zone requrement ->
  const [files, setFiles] = useState([]);
  const [binary, setBinary] = useState([]);
  const {getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive} = useDropzone({
    accept: "image/jpeg", // "image/*" , image/png;
    multiple: false,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          setBinary(reader.result)
        }
        reader.readAsArrayBuffer(file)
      })
    }
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          class="img"
        />
      </div>
    </div>
  ));

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);


  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
   files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const backToCustomersList = () => {
// console.debug("back")
    history.goBack();
  };

  const classes = useStyles();
  const theme = useTheme();
  const [machineName, setmachineName] = React.useState([]);
  const [saveMachine, setSaveMachine] = React.useState([]);
  function handleChange(event) {

    setmachineName(event.target.value);
    let machines = "";
    event.target.value.map (machine => {
      // console.debug(machine);
      machines += "," + machine;
    });
    //console.debug(machines);
    setSaveMachine(machines.substr(1));
  }

  // const LicenseSelect = ({mname}) => {
  //   return (
  //   <>
  //     <MSelect
  //     name={mname}
  //     multiple
  //     defaultValue={names}
  //     value={machineName}
  //     onChange={handleChange}
  //     input={<MInput id="select-multiple-chip" disableUnderline="false"/>}
  //     renderValue={selected => (
  //       <div className={classes.chips}>
  //         {selected.map(value => (
  //           // <Chip key={value} label={value} className={classes.chip} />
  //           <Chip key={value} label={value} className="label label-lg label-light-success label-inline" style={chipStyle}/>
  //           ))}
  //       </div>
  //     )}
  //     MenuProps={MenuProps}
  //     >
  //     {names.map(name => (
  //       <MenuItem key={name} value={name} style={getStyles(name, machineName, theme)}>
  //         {name}
  //       </MenuItem>
  //     ))}
  //     </MSelect>
  //   </>
  //   );
  // }
    return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          // console.debug(saveMachine);
          saveCustomer(values, binary, saveMachine);
        }}
      >
        {({ handleSubmit }) => (
          <>
          {actionsLoading && (
            <div className="overlay-layer bg-transparent">
              <div className="row justify-content-center">
                <div className="spinner spinner-lg spinner-success" />
              </div>
            </div>
          )}
          <Form className="form form-label-right">
            <div className="form-group row">

              <div className="form-group col-sm-2">
                  <label>顔写真</label>
                  <div style={thumbsContainer}>
                      {thumbs}
                  </div>
                  <div style={fileSelect}>
                    <div {...getRootProps({style})}>
                      <input {...getInputProps()} />
                      {
                        <p>ファイル選択</p>
                      }
                    </div>
                  </div>
              </div>

              <div className="form-group col-sm-4">
                {/* Company */}
                <div>
                  <Field
                    name="company"
                    component={Input}
                    placeholder="Company"
                    label="所属会社"
                  />
                </div>
                <p></p>
                {/* ID */}
                <div>
                  <Field
                    name="id"
                    component={Input}
                    placeholder="ID"
                    label="作業者ID"
                  />
                </div>
                <p></p>
                <div className="form-group row">
                  <div className="col-sm-6">
                    {/* Last Name */}
                    <Field
                      name="last_name"
                      component={Input}
                      placeholder="Last Name"
                      label="姓"
                    />
                  </div>
                  <div className="col-sm-6">
                    {/* First Name */}
                    <Field
                      name="first_name"
                      component={Input}
                      placeholder="First Name"
                      label="名"
                    />
                  </div>
                </div>
                {/* Career */}
                <div>
                  <Field
                    name="career"
                    component={Textarea}
                    placeholder="Career"
                    label="業務経歴"
                  />
                </div>
              </div>
                
              <div className="form-group col-sm-6">
              {/* Licensed Machine */}
                <InputLabel htmlFor="select-multiple-chip">運転可能な建機</InputLabel>
                <div style={licenseStyle}>
                  {/* <Field name="machine_license"
                         component={LicenseSelect("machine_license")}
                         placeholder="Photo"
                         label="顔写真"
                  /> */}
                  <MSelect
                    name="machine_license"
                    multiple
                    defaultValue={names}
                    value={machineName}
                    onChange={handleChange}
                    input={<MInput id="select-multiple-chip" disableUnderline="false"/>}
                    renderValue={selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          // <Chip key={value} label={value} className={classes.chip} />
                          <Chip key={value} label={value} className="label label-lg label-light-success label-inline" style={chipStyle}/>
                          ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {names.map(name => (
                      <MenuItem key={name} value={name} style={getStyles(name, machineName, theme)}>
                        {name}
                      </MenuItem>
                    ))}
                  </MSelect>
                </div>
                <p></p>
                <Field
                  name="memo"
                  component={Textarea}
                  placeholder="Memo"
                  label="メモ"
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-2 text-right">
              </div>
              <div className="col-sm-10 text-right">
                <button
                  type="button"
                  onClick={backToCustomersList}
                  className="btn py-sm-1 btn-light"
                  style={cancelButtonStyle}
                >
                 キャンセル
                </button>
                <button
                  type="submit"
                  onSubmit={() => handleSubmit()}
                  className="btn btn-primary py-sm-1"
                  style={submitButtonStyle}
                >
                 登録
                </button>
              </div>
            </div>
          </Form>
        </>
        )}
      </Formik>
    </>
  );
}
