/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
// import SVG from "react-inlinesvg";
import { /*toAbsoluteUrl,*/ checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
/*★★★★★ */
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li 
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/operation')}`}>
                <NavLink className="menu-link menu-toggle" to="/operation">
                    <span className="menu-text">ダッシュボード</span>
                    <i className="menu-arrow"></i>
                    {/* {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)} */}
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/operation/lists')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/operation/lists">
                                <span className="menu-text">
                                稼働状況一覧
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/operation/history')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/operation/history">
                                <span className="menu-text">
                                履歴
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/operation/inspection-result')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/operation/inspection-result">
                                <span className="menu-text">
                                建機の点検結果
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                    </ul>
                </div>
            </li>
            {/*end::1 Level*/}

            {/*Classic submenu*/}
            {/*begin::1 Level*/}
            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/information')}`}>
                <NavLink className="menu-link menu-toggle" to="/information">
                    <span className="menu-text">各種登録情報</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/e-commerce/customers')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/e-commerce/customers">
                                <span className="menu-text">
                                作業者一覧
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/information/admin')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/information/admin">
                                <span className="menu-text">
                                管理者一覧
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/information/site')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/information/site">
                                <span className="menu-text">
                                現場一覧
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/information/company')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/information/company">
                                <span className="menu-text">
                                会社一覧
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/information/machine')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/information/machine">
                                <span className="menu-text">
                                建機一覧
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                    </ul>
                </div>
            </li>
            {/*end::1 Level*/}

            {/*Mega submenu*/}
            {/*begin::1 Level*/}
            <li
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/dashboard')}`}
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
            >
                <NavLink className="menu-link menu-toggle" to="/setting">
                    <span className="menu-text">設定</span>
                    <i className="menu-arrow"></i>
                    {/* <i className="menu-arrow"></i> */}
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">
                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/setting/presite')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/setting/presite">
                                <span className="menu-text">
                                サイト設定
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                        {/*begin::2 Level*/}
                        <li
                            className={`menu-item menu-item-submenu ${getMenuItemActive('/setting/personal')}`}
                            data-menu-toggle="hover"
                            aria-haspopup="true"
                        >
                            <NavLink className="menu-link" to="/setting/personal">
                                <span className="menu-text">
                                個人設定
                                </span>
                            </NavLink>
                        </li>
                        {/*end::2 Level*/}
                    </ul>
                </div>
            </li>

            {/*Classic submenu*/}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
