import { API } from "aws-amplify";
import { Buffer } from "buffer"
import filterSortUtils from "./customersUtils";
/*★★★★★*/

//export const CUSTOMERS_URL = "api/customers";

// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer, photobinary, machineid) {
  // console.debug(machineid)
  // console.debug(thumbnail)
  let base64 = Buffer.from(photobinary).toString('base64')
  // console.debug(base64)
  return new Promise((resolve, reject) => {
    try {
      API.post("mysqldata", "/mysqldata",  
      {
        "body": 
        {
          "request": "post_test", //set or get
          "photo": base64,
          "worker_id": customer.id,
          "last_name": customer.last_name,
          "first_name": customer.first_name,
          "machine_class_id": machineid,
          "company": customer.company,
          "career": customer.career,
          "memo": customer.memo,
          // "machine_status": "Run", //Run or Stop
          // "latest_ope_machine": "MST-VTD200-0001",
          // "latest_ope_date": "2020/11/17 13:10",
          // "regist_date": "2020/11/16 23:30",
          // "machine_class_id": "MST-VTD200, MST-VTD1000"
        }
      })
      .then ((res) => {
        // console.debug(res)
        return resolve(res)
      })
    } catch (error) {
    //  console.debug(error)
      return reject(error)
    }
  })
  // return axios.post(CUSTOMERS_URL, { customer });
}

// READ 未使用
export function getAllCustomers() {
//  return axios.get(CUSTOMERS_URL);
    return null;
}

export function getCustomerById(customerId) {
//  return axios.get(`${CUSTOMERS_URL}/${customerId}`);
  return new Promise((resolve, reject) => {
    try {
      API.post("mysqldata", "/mysqldata",  
      {
        "body": 
        {
          "request": "getone_test",
          "worker_id": customerId,
        }
    })
    .then ((res) => {
      // console.debug(res)
      return resolve(res)
    })
    } catch (error) {
      // console.debug(error)
      return reject(error)
    }
  })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
// let d = new Date();
// let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + '.' + d.getMilliseconds();
// console.debug('findCustomers s',time)
// console.debug(queryParams.pageSize)
let startid = (queryParams.pageNumber-1) * (queryParams.pageSize) + 1;
let endid = startid + (queryParams.pageSize-1)
//console.debug(startid);
//console.debug(endid);
return new Promise((resolve, reject) => {
    try {
      API.post("mysqldata", "/mysqldata",  
      {
        "body": 
        {
          "request": "get_test", //set or get
          "startid": startid, //set or get
          "endid": endid,
        }
      })
      .then ((res) => {
// d = new Date();
// time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + '.' + d.getMilliseconds();
// console.debug('findCustomers r',time)
// console.debug(res.data.totalCount)
        let filterdCustomers = {};
        if( res.data.totalCount != 0 ){
          const utils = new filterSortUtils();
          if( queryParams.filter.status === undefined ) {
            delete queryParams.filter.status;
          }
          filterdCustomers = utils.baseFilter(
              res.data.entities,
              queryParams
          );
        } else {
          const array = [];
          filterdCustomers = { data: 
            { entities: array, totalCount: 0, errorMessage: "" },
          }
        }
// console.debug(filterdCustomers);
// d = new Date();
// time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + '.' + d.getMilliseconds();
// console.debug('findCustomers e',time)
        return resolve(filterdCustomers);
        // return resolve(res)
      })
    } catch (error) {
//      console.debug(error)
      return reject(error)
    }
  })
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer, photobinary, machineid) {
//  return axios.put(`${CUSTOMERS_URL}/${customer.id}`, { customer });
  // console.debug(photobinary)
  // console.debug(customer)
  let base64 = Buffer.from(photobinary).toString('base64')
  return new Promise((resolve, reject) => {
    try {
      API.post("mysqldata", "/mysqldata",  
      {
        "body": 
        {
          "request": "update_test", //set or get
          "photo": base64,
          "worker_id": customer.id,
          "last_name": customer.last_name,
          "first_name": customer.first_name,
          //"machine_class_id": 1,
          "machine_class_id": machineid,
          "company": customer.company,
          "career": customer.career,
          "memo": customer.memo,
          // "machine_status": "Run", //Run or Stop
          // "latest_ope_machine": "MST-VTD200-0001",
          // "latest_ope_date": "2020/11/17 13:10",
          // "regist_date": "2020/11/16 23:30",
          // "machine_class_id": "MST-VTD200, MST-VTD1000"
        }
      })
      .then ((res) => {
        // console.debug(res)
        return resolve(res)
      })
    } catch (error) {
    //  console.debug(error)
      return reject(error)
    }
  })
}

// UPDATE Status 未使用
export function updateStatusForCustomers(ids, status) {
//   return axios.post(`${CUSTOMERS_URL}/updateStatusForCustomers`, {
//     ids,
//     status
//   });
    return null;
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
//   return axios.delete(`${CUSTOMERS_URL}/${customerId}`);
  return new Promise((resolve, reject) => {
    try {
      API.post("mysqldata", "/mysqldata",  
      {
        "body": 
        {
          "request": "delete_test",
          "worker_id": customerId,
        }
      })
      .then ((res) => {
        // console.debug(res)
        return resolve(res)
      })
    } catch (error) {
    //  console.debug(error)
      return reject(error)
    }
  })
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
//   return axios.post(`${CUSTOMERS_URL}/deleteCustomers`, { ids });
  return new Promise((resolve, reject) => {
    try {
      API.post("mysqldata", "/mysqldata",  
      {
        "body": 
        {
          "request": "deletes_test",
          "worker_id": ids,
        }
      })
      .then ((res) => {
        // console.debug(res)
        return resolve(res)
      })
    } catch (error) {
    //  console.debug(error)
      return reject(error)
    }
  })
}

// Get FaceImage by id
export function getFaceImage(id) {
  //console.debug(id);
  return new Promise((resolve, reject) => {
      try {
        API.post("mysqldata", "/mysqldata",  
        {
          "body": 
          {
            "request": "getImage_test",
            "worker_id": id,
          }
        })
        .then ((res) => {
          //console.debug(res)
          return resolve(res)
        })
      } catch (error) {
      //  console.debug(error)
        return reject(error)
      }
    })
  }

// set threshold
export function setThreshold(val) {
  // console.debug(val);
  return new Promise((resolve, reject) => {
      try {
        API.post("mysqldata", "/mysqldata",  
        {
          "body": 
          {
            "request": "setThreshold_test",
            "threshold": val,
          }
        })
        .then ((res) => {
          // console.debug(res)
          return resolve(res)
        })
      } catch (error) {
      //  console.debug(error)
        return reject(error)
      }
    })
  }
  
// load threshold
export function loadThreshold() {
  // console.debug("loadThreshold");
  return new Promise((resolve, reject) => {
      try {
        API.post("mysqldata", "/mysqldata",  
        {
          "body": 
          {
            "request": "loadThreshold_test",
          }
        })
        .then ((res) => {
          // console.debug(res)
          return resolve(res)
        })
      } catch (error) {
      //  console.debug(error)
        return reject(error)
      }
  })
}

//稼働状況一覧／履歴
export function findCustomersWork(queryParams, query) {
  let startid = (queryParams.pageNumber-1) * (queryParams.pageSize) + 1;
  let endid = startid + (queryParams.pageSize-1);
  let sort = queryParams.sortOrder;
  // console.debug(queryParams.sortOrder)
  // console.debug(endid)
  return new Promise((resolve, reject) => {
      try {
        API.post("mysqldata", "/mysqldata",  
        {
          "body": 
          {
            "request": "getwork_test", //set or get
            "startid": startid, //set or get
            "endid": endid,
            "query": query,
            "sort": sort,
          }
        })
        .then ((res) => {
          // console.debug(res.data)
          let filterdCustomers = {};
          if( res.data.totalCount != 0 ){
            const utils = new filterSortUtils();
            if( queryParams.filter.status === undefined ) {
              delete queryParams.filter.status;
            }
          // console.debug(queryParams);
          filterdCustomers = utils.baseFilter(
                res.data.entities,
                queryParams
            );
          } else {
            const array = [];
            filterdCustomers = { data: 
              { entities: array, totalCount: 0, errorMessage: "" },
            }
          }
  // console.debug(filterdCustomers);
  // d = new Date();
  // time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + '.' + d.getMilliseconds();
  // console.debug('findCustomers e',time)
          return resolve(filterdCustomers);
          // return resolve(res)
        })
      } catch (error) {
  //      console.debug(error)
        return reject(error)
      }
    })
  }

export function findHistoryStatusMachines(queryParams, query) {
  let startid = (queryParams.pageNumber-1) * (queryParams.pageSize) + 1;
  let endid = startid + (queryParams.pageSize-1);
  let sort = queryParams.sortOrder;

  // console.debug(queryParams.sortOrder)
  // console.debug(endid)
  return new Promise((resolve, reject) => {
    try {
      API.post("mysqldata", "/mysqldata",
          {
            "body":
                {
                  "request": "getstatusmachine_test", //set or get
                  "startid": startid, //set or get
                  "endid": endid,
                  "query": query,
                  "sort": sort,
                }
          })
          .then ((res) => {
            // console.debug(res.data)
            let filterdCustomers = {};
            if( res.data.totalCount != 0 ){
              const utils = new filterSortUtils();
              if( queryParams.filter.status === undefined ) {
                delete queryParams.filter.status;
              }
              // console.debug(queryParams);
              filterdCustomers = utils.baseFilterHistoryStatusMachine(
                  res.data.entities,
                  queryParams
              );
            } else {
              const array = [];
              filterdCustomers = { data:
                    { entities: array, totalCount: 0, errorMessage: "" },
              }
            }
            // console.debug(filterdCustomers);
            // d = new Date();
            // time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + '.' + d.getMilliseconds();
            // console.debug('findCustomers e',time)
            return resolve(filterdCustomers);
            // return resolve(res)
          })
    } catch (error) {
      //      console.debug(error)
      return reject(error)
    }
  })
}
  
  // request CSV file
export function requestCSV(query) {
  // console.debug("request CSV");
  return new Promise((resolve, reject) => {
      try {
        API.post("createCSVfile", "/createCSVfile",  
        {
          "body": 
          {
            "query": query,
          }
        })
        .then ((res) => {
          //console.debug(res.Location)
          return resolve(res.Location)
        })
      } catch (error) {
      //  console.debug(error)
        return reject(error)
      }
  })
}

// set logo
export function setLogo(binary) {
  // console.debug(val);
  return new Promise((resolve, reject) => {
      try {
        API.post("mysqldata", "/mysqldata",  
        {
          "body": 
          {
            "request": "setLogo_test",
            "threshold": binary,
          }
        })
        .then ((res) => {
          // console.debug(res)
          return resolve(res)
        })
      } catch (error) {
        // console.debug(error)
        return reject(error)
      }
    })
  }
