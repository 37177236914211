/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../_redux/customers/customersActions";
// import { useDropzone } from "react-dropzone";
// import * as Yup from "yup";
import { useCustomersUIContext } from "./CustomersUIContext";
import { CustomerEditForm } from "./customer-edit-dialog/CustomerEditForm";
import { CustomerEditFormNew } from "./customer-edit-dialog/CustomerEditFormNew";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
/*★★★★★*/
// const CustomerEditSchema = Yup.object().shape({
//   first_name: Yup.string()
//     .min(1, "Minimum 3 symbols")
//     .max(50, "Maximum 50 symbols")
//     .required("Firstname is required"),
//   last_name: Yup.string()
//     .min(1, "Minimum 3 symbols")
//     .max(50, "Maximum 50 symbols")
//     .required("Lastname is required"),
// });

// // drop-zone definition
// const thumbsContainer = {
//   display: 'flex',
//   flexDirection: 'row',
//   flexWrap: 'wrap',
//   marginTop: 16
// };

// const thumb = {
//   display: 'inline-flex',
//   borderRadius: 2,
//   border: '1px solid #eaeaea',
//   marginBottom: 8,
//   marginRight: 8,
//   width: 100,
//   height: 100,
//   padding: 4,
//   boxSizing: 'border-box'
// };

// const thumbInner = {
//   display: 'flex',
//   minWidth: 0,
//   overflow: 'hidden'
// };

// const img = {
//   display: 'block',
//   width: 'auto',
//   height: '100%'
// };


export function CustomersEdit({
  history, id 
}) {
// console.debug(id)
// console.debug('CustomersEdit','history=',history,'id=',id)
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit, customers } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
      customers: state.customers.entities,
    }),
    shallowEqual
  );

  useEffect(() => {
    // console.debug('useEffect');
    // server call for getting Customer by id
    // dispatch(actions.fetchCustomer(id)).then(() => {console.debug('customer edit dispatch')});
    dispatch(actions.fetchCustomer(id));
  }, [id, dispatch]);

  // server request for saving customer
  const saveCustomer = (customer, binary, machineid) => {
    if (!id) {
      // console.debug(machineid)
      dispatch(actions.createCustomer(customer, binary, machineid)).then(() => backToCustomerList());
      // dispatch(actions.createCustomer(customer, binary, machieId)).then(() => history.goBack());
    } else {
      // console.debug(machineid)
      // dispatch(actions.updateCustomer(customer, binary, machieId)).then(() => history.goBack());
      dispatch(actions.updateCustomer(customer, binary, machineid)).then(() => backToCustomerList());
    }
  };

  const btnRef = useRef();  
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToCustomerList = () => {
    history.push(`/e-commerce/customers`);
  };

  // console.debug(customersUIProps.initCustomer)
  // console.debug(customers)
  let ent = null;
  if( customers != null ){
    customers.map((entity) => {
      if( entity.id == id ){
        // console.debug(entity.id);
        ent = Object.assign({}, entity);
      }
    })
    // console.debug(ent);
  }

  if( id === undefined ) {
    return (
      <Card>
        <CardHeader title="作業者登録"/>
        <CardBody>
          <CustomerEditFormNew
              saveCustomer={saveCustomer}
              actionsLoading={actionsLoading}
              customer={customersUIProps.initCustomer}
              // customer={customerForEdit || customersUIProps.initCustomer}
              history={history}
          />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title="作業者登録"/>
      <CardBody>
        <CustomerEditForm
            saveCustomer={saveCustomer}
            actionsLoading={actionsLoading}
            customer={ent || customersUIProps.initCustomer}
            // customer={customerForEdit || customersUIProps.initCustomer}
            history={history}
        />
      </CardBody>
    </Card>
  );
}
