import * as requestFromServer from "./customersCrud";
import {customersSlice, callTypes} from "./customersSlice";
import {findHistoryStatusMachine} from "./customersCrud";

const {actions} = customersSlice;
/*★★★★★*/
export const fetchCustomers = queryParams => dispatch => {
  // let d = new Date();
  // let time = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds() + '.' + d.getMilliseconds();
  // console.debug('fetchCustomers ',time)
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCustomers(queryParams)
    .then(response => {
  // console.debug(response)
      //★★★ここで10件のデータが返ってくる
      const { totalCount, entities } = response.data;
      // console.debug(entities);
      dispatch(actions.customersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find customers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCustomer = id => dispatch => {
  if (!id) {
  //  console.debug(id)
    return dispatch(actions.customerFetched({ customerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCustomerById(id)
    .then(response => {
    //  console.debug(response)
      const customer = response.data;
      dispatch(actions.customerFetched({ customerForEdit: customer }));
    })
    .catch(error => {
      error.clientMessage = "Can't find customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomer = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(id)
    .then(response => {
    //  console.debug(response)
      dispatch(actions.customerDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCustomer = (customerForCreation, photobinary, machineId) => dispatch => {
  // console.debug(customerForCreation)
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCustomer(customerForCreation, photobinary, machineId)
    .then(response => {
    //  console.debug(response)
      const { customer } = response.data;
      dispatch(actions.customerCreated({ customer }));
    })
    .catch(error => {
      error.clientMessage = "Can't create customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCustomer = (customer, photobinary, machineId) => dispatch => {
  // console.debug(customer)
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCustomer(customer, photobinary, machineId)
    .then(() => {
    //  console.debug(customer)
      dispatch(actions.customerUpdated({ customer }));
    })
    .catch(error => {
      error.clientMessage = "Can't update customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCustomersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then(() => {
    //  console.debug(ids)
      dispatch(actions.customersStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update customers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
    //  console.debug(ids)
      dispatch(actions.customersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete customers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getFaceImage = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
  .getFaceImage(id)
  .then((res) => {return res;})
};

export const setThreshold = val => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
  .setThreshold(val)
  .then((res) => {return res;})
};

export const loadThreshold = () => dispatch => {
  // console.debug("loadThreshold");
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
  .loadThreshold()
  .then((res) => {return res;})
};

export const fetchCustomersWork = (queryParams, query) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCustomersWork(queryParams, query)
    .then(response => {
  // console.debug(response)
      //★★★ここで10件のデータが返ってくる
      const { totalCount, entities } = response.data;
      // console.debug(entities);
      dispatch(actions.customersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find customers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchHistoryStatusMachine = (queryParams, query) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
      .findHistoryStatusMachine(queryParams, query)
      .then(response => {
        // console.debug(response)
        //★★★ここで10件のデータが返ってくる
        const { totalCount, entities } = response.data;
        // console.debug(entities);
        dispatch(actions.historyStatusMachineFetched({ totalCount, entities }));
      })
      .catch(error => {
        console.log("Can't find customers")
        error.clientMessage = "Can't find customers";
        dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
};

export const deleteALLCustomers = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.customersALLDeleted());
};

export const requestCSV = (query) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .requestCSV(query)
    .then((res) => {
    //  console.debug(ids)
      return(res);
    })
    .catch(error => {
      error.clientMessage = "Can't request CSV";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setLogo = binary => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
  .setLogo(binary)
  .then((res) => {return res;})
};
