import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/customers/customersActions";
import {useCustomersUIContext} from "../CustomersUIContext";
//★★★★★
const BTN_WIDTH = 80
const BTN_HEIGHT = 32

const cancelButtonStyle = {
  width: BTN_WIDTH,
  height: BTN_HEIGHT,
  marginRight: 6,
  fontSize: 9,
  borderColor: '#e4e6ef' 
}
const deleteButtonStyle = {
  width: BTN_WIDTH,
  height: BTN_HEIGHT
}

export function CustomerDeleteDialog({ id, show, onHide }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.customers.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteCustomer = () => {
    // server request for deleting customer by id
    dispatch(actions.deleteCustomer(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchCustomers(customersUIProps.queryParams));
      // clear selections list
      customersUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          作業者削除
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          // <span>Are you sure to permanently delete this customer?</span>
          <span>完全に削除しますか?</span>
          )}
        {/* {isLoading && <span>Customer is deleting...</span>} */}
        {isLoading && <span>削除中...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn py-sm-1 btn-light"
            style={cancelButtonStyle}
          >
            キャンセル
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteCustomer}
            className="btn btn-primary py-sm-1"
            style={deleteButtonStyle}
          >
            削除
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
