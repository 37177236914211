import axios from "axios";
// import Amplify from "aws-amplify";
// import awsmobile from "../../../../../aws-exports";
// import {API} from "aws-amplify";
// Amplify.configure(awsmobile)
import * as dbAccess from "./customersDbaccess";
import {findHistoryStatusMachines} from "./customersDbaccess";
/*★★★★★*/
const MOCKSTITCH = 'mockk'

export const CUSTOMERS_URL = "api/customers";

// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer, photobinary, machineId) {
  // console.debug(customer)
  if (MOCKSTITCH=='mock') return axios.post(CUSTOMERS_URL, { customer });
  return dbAccess.createCustomer(customer, photobinary, machineId);
}

// READ 未使用
export function getAllCustomers() {
//  console.debug("getAllCustomers");
 if (MOCKSTITCH=='mock') return axios.get(CUSTOMERS_URL);
}

export function getCustomerById(customerId) {
//  console.debug(customerId);
  if (MOCKSTITCH=='mock') return axios.get(`${CUSTOMERS_URL}/${customerId}`);
  return dbAccess.getCustomerById(customerId);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
  // ここでクエリーに従ったデータをaxiosが返す
  if (MOCKSTITCH=='mock') return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
  return dbAccess.findCustomers(queryParams);
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer, photobinary, machineid) {
//  console.debug(customer);
  if (MOCKSTITCH=='mock') return axios.put(`${CUSTOMERS_URL}/${customer.id}`, { customer });
  return dbAccess.updateCustomer(customer, photobinary, machineid);
}

// UPDATE Status　未使用
export function updateStatusForCustomers(ids, status) {
//  console.debug(ids, status);
 if (MOCKSTITCH=='mock') return axios.post(`${CUSTOMERS_URL}/updateStatusForCustomers`, {
    ids,
    status
  });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
//  console.debug(customerId);
 if (MOCKSTITCH=='mock') return axios.delete(`${CUSTOMERS_URL}/${customerId}`);
 return dbAccess.deleteCustomer(customerId);
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
//  console.debug(ids);
 if (MOCKSTITCH=='mock') return axios.post(`${CUSTOMERS_URL}/deleteCustomers`, { ids });
 return dbAccess.deleteCustomers(ids);
}

// Get FaceImage by id
export function getFaceImage(id) {
  //  console.debug(id);
   return dbAccess.getFaceImage(id);
}

// set threshold
export function setThreshold(val) {
  //  console.debug(id);
   return dbAccess.setThreshold(val);
}

// load threshold
export function loadThreshold() {
  //  console.debug("loadThreshold");
   return dbAccess.loadThreshold();
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomersWork(queryParams, query) {
  // ここでクエリーに従ったデータをaxiosが返す
  if (MOCKSTITCH=='mock') return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
  return dbAccess.findCustomersWork(queryParams, query);
}

// Get History Status Machine
export function findHistoryStatusMachine(queryParams, query) {
    // ここでクエリーに従ったデータをaxiosが返す
    if (MOCKSTITCH=='mock') return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
    return dbAccess.findHistoryStatusMachines(queryParams, query);
}
export function requestCSV(query) {
  console.debug(query);
  return dbAccess.requestCSV(query);
}

// set logo
export function setLogo(binary) {
  //  console.debug(id);
   return dbAccess.setLogo(binary);
}
