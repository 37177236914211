// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import {
  CustomerLicenseCssClasses,
  CustomerLicenseTitles,
} from "../../CustomersUIHelpers";
/*★★★★★*/

export function LicenseColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      CustomerLicenseCssClasses[1]
    } label-inline`;
  };

  const formControl = {
    marginBottom: 5,
  };

  let license = row?.machine_license?.split(",")
  let licenses = license?.map(license => {
    // console.debug(license)
    if( license == "") return "";
    return (
      <div style={formControl}>
        <span className={getLabelCssClasses()}>
          {license}
        </span>
        <br/>
      </div>
    )});

  return (
    licenses
  );
}
