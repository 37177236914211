import React from "react";
import { CustomersUIProvider } from "./CustomersUIContext";
import { useLocation } from "react-router-dom";
import {HistoryStatusMachineCard} from "./HistoryStatusMachineCard";
/*★★★★★*/
export function HistoryStatusMachine({ history }) {
    const location = useLocation();
    const customersUIEvents = {
        openEditCustomerDialogOnlyMemo: (id, path) => {
            history.push(`/operation/${path}/${id}/edit`);
        },
        openCustomerFacejudgeDialog: (id, path) => {
            history.push(`/operation/${path}/${id}/facejudge`);
        }
    }

    return (
        <CustomersUIProvider customersUIEvents={customersUIEvents}>
            <HistoryStatusMachineCard />
        </CustomersUIProvider>
    );
}
