import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter, initialFilterHistory, initialFilterStatusMachine} from "./CustomersUIHelpers";
import { useLocation } from "react-router-dom";

const CustomersUIContext = createContext();

export function useCustomersUIContext() {
  return useContext(CustomersUIContext);
}

export const CustomersUIConsumer = CustomersUIContext.Consumer;
/*★★★★★*/
export function CustomersUIProvider({customersUIEvents, children}) {
  // 現在のパス
  const location = useLocation();
  let filter = null;
  if ( location.pathname.match(/history|lists/) ){
    filter = initialFilterHistory;
  } else if (location.pathname.match(/inspection-result/)) {
    filter = initialFilterStatusMachine;
  } else {
    filter = initialFilter;
  }
//  console.debug(filter);
//  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [queryParams, setQueryParamsBase] = useState(filter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCustomer = {
    id: undefined,
    first_name: "",
    last_name: "",
    company: "",
    status: 0,
    latest_ope_machine: "",
    latest_ope_date: "",
    machine_license: 1,
    face_judge: "OK",
    memo: "-",
    career: "",
    location: "",
    photo: "",
    worker: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCustomer,
    newCustomerButtonClick: customersUIEvents.newCustomerButtonClick,
    openEditCustomerDialog: customersUIEvents.openEditCustomerDialog,
    openDeleteCustomerDialog: customersUIEvents.openDeleteCustomerDialog,
    openDeleteCustomersDialog: customersUIEvents.openDeleteCustomersDialog,
    openFetchCustomersDialog: customersUIEvents.openFetchCustomersDialog,
    openUpdateCustomersStatusDialog: customersUIEvents.openUpdateCustomersStatusDialog,
    openCustomerFacejudgeDialog: customersUIEvents.openCustomerFacejudgeDialog
  };

  return <CustomersUIContext.Provider value={value}>{children}</CustomersUIContext.Provider>;
}