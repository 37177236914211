import React, { useEffect, /*useMemo*/ useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
// import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader";
// import { CustomerEditForm } from "./CustomerEditForm";
// import { useCustomersUIContext } from "../CustomersUIContext";
import { CustomerFacejudgeForm } from "./CustomerFacejudgeForm"
/*★★★★★*/
export function CustomerFacejudgeDialog({ id, show, onHide }) {
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
    }),
    shallowEqual
  );

  const [faceimage, setImage] = useState([]);

  useEffect(() => {
    setImage("");
    if(id){
      dispatch(actions.getFaceImage(id)).then((res) => {
        setImage(res.image);
      })
    }
  }, [id, dispatch]);

  if ( show == false ) return(() => {});

  return (
    <Modal
      // size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          顔認証結果
        </Modal.Title>
      </Modal.Header>
      <CustomerFacejudgeForm
        id={id}
        faceimage={faceimage}
        onHide={onHide}
        show={show}
     />
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate h-100"
          >
            OK
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
