import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import { useDispatch } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import {useLocation} from "react-router-dom";
/*★★★★★*/
const prepareFilter = (queryParams, values) => {
//  const { status, type, searchText } = values;
    const { result, searchText } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    // Filter by status
    filter.result = result;
    // // Filter by type
    // filter.type = type !== "" ? +type : undefined;
    // Filter by all fields
    // filter.management_no = searchText;
    if (searchText) {
        filter.management_no = searchText;
        // filter.first_name = searchText;
        // filter.last_name = searchText;
        // filter.company = searchText;
        // filter.email = searchText;
        // filter.ipAddress = searchText;
    }
    newQueryParams.filter = filter;
    // console.log(newQueryParams)
    return newQueryParams;
};

const filterButtonStyle = {
    marginTop: 10,
    marginLeft: 10,
    paddingTop: 5,
    height: 27
}

export function HistoryStatusMachineFilter({ listLoading }) {
    // Customers UI Context
    const customersUIContext = useCustomersUIContext();
    const customersUIProps = useMemo(() => {
        return {
            queryParams: customersUIContext.queryParams,
            setQueryParams: customersUIContext.setQueryParams,
        };
    }, [customersUIContext]);

    const [query, setQuery] = useState("");
    // queryParams, setQueryParams,
    const applyFilter = (values) => {
        setQuery(values.searchText);
        const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
        if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            // update list by queryParams
            // console.debug(newQueryParams)
            customersUIProps.setQueryParams(newQueryParams);
        }
    };

    const dispatch = useDispatch();
    const location = useLocation();


    return (
        <>
            <Formik
                initialValues={{
                    result: "", // values => All=""/Susspended=0/Active=1/Pending=2
                    searchText: ""
                    // type: ""
                }}
                onSubmit={(values) => {
                    applyFilter(values);
                }}
            >
                {({
                      values,
                      handleSubmit,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit} className="form form-label-right">
                        <div className="form-group row">
                            <div className="col-lg-3">
                                <select
                                    className="form-control"
                                    name="result"
                                    placeholder="Filter by Machine Status"
                                    // TODO: Change this code
                                    onChange={(e) => {
                                        setFieldValue("result", e.target.value);
                                        handleSubmit();
                                    }}
                                    onBlur={handleBlur}
                                    value={values.status}
                                >
                                    <option value="">全て</option> //All
                                    <option value="OK">OK</option> //Suspended
                                    <option value="NG">NG</option> //Active
                                </select>
                                <small className="form-text text-muted">
                                    点検結果でフィルター
                                </small>
                            </div>
                            <div className="col-lg-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="searchText"
                                    placeholder="検索"
                                    onBlur={handleBlur}
                                    value={values.searchText}
                                    onChange={(e) => {
                                        setFieldValue("searchText", e.target.value);
                                        handleSubmit();
                                    }}
                                />
                                <small className="form-text text-muted">
                                    管理番号で検索
                                </small>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
}
