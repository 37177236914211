/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

/*★★★★★ */
// const menuStyle = {
//   fontSize: 14,
//   color: 'white'
// }

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

       {/* Dashboard */}
        {/* begin::section */}
        {/* <li className="menu-section "> */}
          {/* <h4 className="menu-text" style={menuStyle}>ダッシュボード</h4> */}
          {/* <i className="menu-icon flaticon-more-v2"></i> */}
          {/* <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
          </span> */}
        {/* </li> */}
        {/* end:: section */}
       {/*begin::1 Level*/}
       <li
         className={`menu-item menu-item-submenu menu-item-open ${getMenuItemActive(
           "/operation",
           false
         )}`}
         aria-haspopup="true"
         data-menu-toggle="hover"
       >
         <NavLink className="menu-link"  to="#">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
          </span>
           <span className="menu-text">ダッシュボード</span>
           {/* <i className="menu-arrow" /> */}
         </NavLink>
         <div className="menu-submenu">
           {/* <ul className="menu-subnav"> */}
             <ul className="menu-subnav">
               <li
                 className="menu-item  menu-item-parent"
                 aria-haspopup="true"
               >
                 <span className="menu-link">
                   <span className="menu-text">Operation Status List</span>
                 </span>
               </li>
             
               {/*begin::2 Level*/}
               <li
                 className={`menu-item ${getMenuItemActive(
                   "/operation/lists"
                 )}`}
                 aria-haspopup="true"
               >
                 <NavLink className="menu-link" to="/operation/lists">
                   <i className="menu-bullet menu-bullet-dot">
                     <span />
                   </i>
                   <span className="menu-text">稼働状況一覧</span>
                 </NavLink>
               </li>
               {/*end::2 Level*/}

               {/*begin::2 Level*/}
               <li
                 className={`menu-item ${getMenuItemActive(
                   "/operation/history"
                 )}`}
                 aria-haspopup="true"
               >
                 <NavLink className="menu-link" to="/operation/history">
                   <i className="menu-bullet menu-bullet-dot">
                     <span />
                   </i>
                   <span className="menu-text">履歴</span>
                 </NavLink>
               </li>
               {/*end::2 Level*/}

               {/*begin::2 Level*/}
               <li
                   className={`menu-item ${getMenuItemActive(
                       "/operation/inspection-result"
                   )}`}
                   aria-haspopup="true"
               >
                 <NavLink className="menu-link" to="/operation/inspection-result">
                   <i className="menu-bullet menu-bullet-dot">
                     <span />
                   </i>
                   <span className="menu-text">建機の点検結果</span>
                 </NavLink>
               </li>
               {/*end::2 Level*/}
             {/* </ul> */}
           </ul>
         </div>
       </li>
       {/*end::1 Level*/}

       {/* Informations */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text" style={menuStyle}>各種登録情報</h4>
          <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
          </span>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}
       {/*begin::1 Level*/}
       <li
         className={`menu-item menu-item-submenu menu-item-open ${getMenuItemActive(
           "/e-commerce",
           false
         )}`}
         aria-haspopup="true"
         data-menu-toggle="hover"
       >
         <NavLink className="menu-link" to="#">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
          </span>
           <span className="menu-text">各種登録情報</span>
           {/* <i className="menu-arrow" /> */}
         </NavLink>
         <div className="menu-submenu ">
           <ul className="menu-subnav">
             <ul className="menu-subnav">
               <li
                 className="menu-item  menu-item-parent"
                 aria-haspopup="true"
               >
                 <span className="menu-link">
                   <span className="menu-text">各種登録情報</span>
                 </span>
               </li>

               {/*begin::2 Level*/}
               <li
                 className={`menu-item ${getMenuItemActive(
                   "/e-commerce"
                 )}`}
                 aria-haspopup="true"
               >
                 <NavLink className="menu-link" to="/e-commerce/customers">
                   <i className="menu-bullet menu-bullet-dot">
                     <span />
                   </i>
                   <span className="menu-text">作業者一覧</span>
                 </NavLink>
               </li>
               {/*end::2 Level*/}

               {/*begin::2 Level*/}
               <li
                 className={`menu-item ${getMenuItemActive(
                   "/information/admin"
                 )}`}
                 aria-haspopup="true"
               >
                 <NavLink className="menu-link" to="/information/admin">
                   <i className="menu-bullet menu-bullet-dot">
                     <span />
                   </i>
                   <span className="menu-text">管理者一覧</span>
                 </NavLink>
               </li>
               {/*end::2 Level*/}

               {/*begin::2 Level*/}
               <li
                 className={`menu-item ${getMenuItemActive(
                   "/information/site"
                 )}`}
                 aria-haspopup="true"
               >
                 <NavLink className="menu-link" to="/information/site">
                   <i className="menu-bullet menu-bullet-dot">
                     <span />
                   </i>
                   <span className="menu-text">現場一覧</span>
                 </NavLink>
               </li>
               {/*end::2 Level*/}


               {/*begin::2 Level*/}
               <li
                 className={`menu-item ${getMenuItemActive(
                   "/information/company"
                 )}`}
                 aria-haspopup="true"
               >
                 <NavLink className="menu-link" to="/information/company">
                   <i className="menu-bullet menu-bullet-dot">
                     <span />
                   </i>
                   <span className="menu-text">会社一覧</span>
                 </NavLink>
               </li>
               {/*end::2 Level*/}


               {/*begin::2 Level*/}
               <li
                 className={`menu-item ${getMenuItemActive(
                   "/information/machine"
                 )}`}
                 aria-haspopup="true"
               >
                 <NavLink className="menu-link" to="/information/machine">
                   <i className="menu-bullet menu-bullet-dot">
                     <span />
                   </i>
                   <span className="menu-text">建機一覧</span>
                 </NavLink>
               </li>
               {/*end::2 Level*/}

             </ul>
           </ul>
         </div>
       </li>
       {/*end::1 Level*/}

       {/* Settings */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text" style={menuStyle}>設定</h4>
          <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
          </span>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}
       {/*begin::1 Level*/}
       <li
         className={`menu-item menu-item-submenu menu-item-open ${getMenuItemActive(
           "/setting",
           true
         )}`}
         aria-haspopup="true"
         data-menu-toggle="hover"
       >
         <NavLink className="menu-link" to="#">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")} />
          </span>
           <span className="menu-text">設定</span>
           {/* <i className="menu-arrow" /> */}
         </NavLink>
         <div className="menu-submenu ">
           <ul className="menu-subnav">
             <ul className="menu-subnav">
               <li
                 className="menu-item  menu-item-parent"
                 aria-haspopup="true"
               >
                 <span className="menu-link">
                   <span className="menu-text">サイト設定</span>
                 </span>
               </li>

               {/*begin::2 Level*/}
               <li
                 className={`menu-item ${getMenuItemActive(
                   "/setting/presite"
                 )}`}
                 aria-haspopup="true"
               >
                 <NavLink className="menu-link menu-toggle" to="/setting/presite">
                   <i className="menu-bullet menu-bullet-dot">
                     <span />
                   </i>
                   <span className="menu-text">サイト設定</span>
                 </NavLink>
               </li>
               {/*end::2 Level*/}

               {/*begin::2 Level*/}
               <li
                 className={`menu-item ${getMenuItemActive(
                   "/setting/personal"
                 )}`}
                 aria-haspopup="true"
               >
                 <NavLink className="menu-link menu-toggle" to="/setting/personal">
                   <i className="menu-bullet menu-bullet-dot">
                     <span />
                   </i>
                   <span className="menu-text">個人設定</span>
                 </NavLink>
               </li>
               {/*end::2 Level*/}

             </ul>
           </ul>
         </div>
       </li>
       {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
