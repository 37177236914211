export const CustomerStatusCssClasses = ["danger", "success", "info"];
export const CustomerStatusTitles = ["停止中", "運転中", "N/A"];
export const CustomerTypeCssClasses = ["success", "primary", ""];
export const CustomerTypeTitles = ["MST-200VDR", "MST-1000VDR", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const CustomerMachineStatusCssClasses = ["danger", "danger", "danger", "danger", "danger", "success", "success"];
export const CustomerMachineStatusTitles = ["停止中", "停止中", "停止中", "停止中", "停止中", "運転中", "運転中"];
/*★★★★★*/
export const initialFilter = {
  filter: {
    last_name: "",
    first_name: ""
    // email: "",
    // ipAddress: ""
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 5
};
export const initialFilterHistory = {
  filter: {
    last_name: "",
    first_name: ""
    // email: "",
    // ipAddress: ""
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 5
};

export const initialFilterStatusMachine = {
  filter: {
    result: "",
    management_no: ""
  },
  sortOrder: "desc", // asc||desc
  sortField: "timestamp",
  pageNumber: 1,
  pageSize: 5
};
export const CustomerLicenseCssClasses = ["danger", "success", "info", ""];
export const CustomerLicenseTitles = ["MST-200VDR", "MST-1000VDR", ""];
