// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import {
    CustomerStatusCssClasses,
    /*CustomerStatusTitles,*/
} from "../../CustomersUIHelpers";

export function ResultColumnFormatter(cellContent, row) {
    /*★★★★★*/
    const getLabelCssClasses = (val) => {
        return `label label-lg label-light-${
            CustomerStatusCssClasses[val]
        } label-inline`;
    };
    const resultView = () => {
        if ( row.result == "OK" ) {
            // console.debug(row.license_judge)
            return (
                <span className={getLabelCssClasses(1)}>
          {row.result}
        </span>
            );
        } else if ( row.result == "NG" ) {
            // console.debug(row.license_judge)
            return (
                <span className={getLabelCssClasses(0)}>
          {row.result}
        </span>
            );
        } else {
            return (
                <span></span>
            )
        };
    }

    return (
        resultView()
    );
}
