import React from "react";
import { Route } from "react-router-dom";
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog";
import { CustomersUIProvider } from "./CustomersUIContext";
import { CustomersOpeCard } from "./CustomersOpeCard";
import { CustomerFacejudgeDialog } from "./customer-facejudge-dialog/CustomerFacejudgeDialog"
import { useLocation } from "react-router-dom";
import { CustomerEditDialogOnlyMemo } from "./customer-edit-dialog/CustomerEditDialogOnlyMemo"
/*★★★★★*/
export function CustomersOpePage({ history }) {
  const location = useLocation();
  let route_facepath, route_editpath = "";
  let back_path = "";
  if ( location.pathname.match('history') ){
    route_facepath = "/operation/history/:id/facejudge";
    route_editpath = "/operation/history/:id/edit";
    back_path = "/operation/history";
  } else {
    route_facepath = "/operation/lists/:id/facejudge";
    route_editpath = "/operation/lists/:id/edit";
    back_path = "/operation/lists";
  }

  const customersUIEvents = {
    openEditCustomerDialogOnlyMemo: (id, path) => {
      history.push(`/operation/${path}/${id}/edit`);
    },
    openCustomerFacejudgeDialog: (id, path) => {
      history.push(`/operation/${path}/${id}/facejudge`);
    }
  }

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      {/* <Route path="/operation/history/:id/facejudge"> */}
      <Route path={route_facepath}>
        {({ history, match }) => (
          <CustomerFacejudgeDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push(`${back_path}`);
            }}
          />
        )}
      </Route>
      <Route path={route_editpath}>
        {({ history, match }) => (
          <CustomerEditDialogOnlyMemo
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(`${back_path}`);
            }}
          />
        )}
      </Route>
      <CustomersOpeCard />
    </CustomersUIProvider>
  );
}
