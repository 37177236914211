// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

export function LinkColumnFormatter(cellContent, row) {
/*★★★★★*/
  const gpsView = () => {
    if( row.location == "" || row.location == null ) {
      return (
        <span></span>
      )
    } else {
      const ref = 'https://www.google.com/maps/search/?api=1&query=' + row.location;
      const loc = row.location.split(',');

      return(
        <a target='_blank' href={ref} >
          {loc[0].substring(0,8)}<br/>
          {loc[1].substring(0,9)}
        </a>
      );
    };
  }
 
  return (
    gpsView()
  );
}
