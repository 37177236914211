import React from "react";
import { CustomersUIProvider } from "./CustomersUIContext";
import { CustomersEdit } from "./CustomersEdit";
/*★★★★★*/
export function CustomersEditPage({ history,  match: {
  params: { id },
},
}) {
  // console.debug(id)
  const customersUIEvents = {
    saveButtonClick: () => {
      history.goBack();
    },
    cancelButtonClick: () => {
      history.goBack();
    },
  }

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersEdit history={history} id={id}/>
    </CustomersUIProvider>
  );
}
