import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import { useDispatch } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import {useLocation} from "react-router-dom";
/*★★★★★*/
const prepareFilter = (queryParams, values) => {
//  const { status, type, searchText } = values;
  const { status, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.status = status !== "" ? +status : undefined;
  // // Filter by type
  // filter.type = type !== "" ? +type : undefined;
  // Filter by all fields
  filter.last_name = searchText;
  if (searchText) {
    filter.latest_ope_date = searchText;
    // filter.first_name = searchText;
    // filter.last_name = searchText;
    // filter.company = searchText;
    // filter.email = searchText;
    // filter.ipAddress = searchText;
  }
  newQueryParams.filter = filter;
  // console.debug(newQueryParams)
  return newQueryParams;
};

const filterButtonStyle = {
  marginTop: 10,
  marginLeft: 10,
  paddingTop: 5,
  height: 27
}

const csvDLButton = (location, csvDL) => {
  // console.debug(location.pathname);
  if (location.pathname.match('history')) {
    return(
      <div className="col-lg-6 ">
        <div className="row align-items-center font-size-sm">
          <button
            type="button"
            className="btn btn-primary py-sm-1"
            onClick={csvDL}
            style={filterButtonStyle}
          >
          CSV DL
          </button>
        </div>
      </div>
    );
  } else {
    return (<div></div>);
  }
}

export function CustomersFilter({ listLoading }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  const [query, setQuery] = useState("");
  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    setQuery(values.searchText); 
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      // console.debug(newQueryParams)
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  const dispatch = useDispatch();
  const csvDL = () => {
    // console.debug(query)
    dispatch(actions.requestCSV(query)).then((res) => {window.location.replace(res)});
  }

  const location = useLocation();


  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Susspended=0/Active=1/Pending=2
          searchText: ""
          // type: ""
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-3">
                <select
                  className="form-control"
                  name="status"
                  placeholder="Filter by Machine Status"
                  // TODO: Change this code
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.status}
                >
                  <option value="">All</option> //All
                  <option value="0">停止中</option> //Suspended
                  <option value="6">運転中</option> //Active
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by ステータス
                </small>
              </div>
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in 稼働日時
                </small>
              </div>
              {csvDLButton(location, csvDL)}
           </div>
          </form>
        )}
      </Formik>
    </>
  );
}
