import React, { Suspense, /*lazy*/ } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { eCommercePage } from "./modules/ECommerce/pages/eCommercePage";
import { CustomersOpePage } from "./modules/ECommerce/pages/customers/CustomersOpePage";
import { Cards } from "./modules/ECommerce/pages/customers/Cards";
import { PreSettings } from "./modules/ECommerce/pages/customers/PreSettings";
import { Settings } from "./modules/ECommerce/pages/customers/Settings";
import {HistoryStatusMachine} from "./modules/ECommerce/pages/customers/HistoryStatusMachine";
// import { DashboardPage } from "./pages/DashboardPage";

export default function BasePage() {
  // console.debug('BasePage')

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/operation/lists" />
          // <Redirect exact from="/" to="/dashboard" />
        }
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        <Route path="/e-commerce" component={eCommercePage} />
        <ContentRoute path="/operation/lists" component={CustomersOpePage} />
        {/* <Route path="/operation/history" component={Cards} /> */}
        <Route path="/operation/history" component={CustomersOpePage} />
        <Route path="/operation/inspection-result" component={HistoryStatusMachine} />
        <Route path="/information/admin" component={Cards} />
        <Route path="/information/site" component={Cards} />
        <Route path="/information/company" component={Cards} />
        <Route path="/information/machine" component={Cards} />
        <Route path="/setting/personal" component={Cards} />
        <Route path="/setting/presite" component={PreSettings} />
        <Route path="/setting/:initval/site">
          {({match}) => (
            <Settings
              initval={match.params.initval}
            />
          )}
          </Route>
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
