// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import {
    getSelectRow,
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
import { useLocation } from "react-router-dom";
import {fetchHistoryStatusMachine} from "../../../_redux/customers/customersActions";
/*★★★★★*/
export function HistoryStatusMachineTable() {
    // Customers UI Context
    const customersUIContext = useCustomersUIContext();
    const customersUIProps = useMemo(() => {
        return {
            ids: customersUIContext.ids,
            setIds: customersUIContext.setIds,
            queryParams: customersUIContext.queryParams,
            setQueryParams: customersUIContext.setQueryParams,
            openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
            openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
            openUpdateCustomersStatusDialog: customersUIContext.openUpdateCustomersStatusDialog,
            openCustomerFacejudgeDialog: customersUIContext.openCustomerFacejudgeDialog
        };
    }, [customersUIContext]);

    // Getting curret state of customers list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.customers }),
        shallowEqual
    );

    const { totalCount, entities, listLoading } = currentState;
    // Customers Redux state
    const dispatch = useDispatch();
    useEffect(() => {
        // clear All local data
        dispatch(actions.deleteALLCustomers());
        // clear selections list
        customersUIProps.setIds([]);
        // console.debug(location);
        dispatch(actions.fetchHistoryStatusMachine(customersUIProps.queryParams, "history"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customersUIProps.queryParams, dispatch]);
    // Table columns
    const columns = [
        {
            dataField: "timestamp",
            text: "稼働日時",
            sort: true,
            sortCaret: sortCaret,
            formatter: columnFormatters.DatetimeColumnFormatter,
            headerSortingClasses,
        },
        {
            dataField: "management_no",
            text: "管理番号",
            headerSortingClasses,
        },
        {
            dataField: "user_name",
            text: "ユーザー名",
            headerSortingClasses,
        },
        {
            dataField: "result",
            text: "点検結果",
            formatter: columnFormatters.ResultColumnFormatter,
            headerSortingClasses,
        },
        {
            dataField: "memo",
            text: "メモ",
            style: {
                width: "400px",
            },
            formatter: columnFormatters.MemoColumnFormatter,
            headerSortingClasses,
        }
    ];
    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: customersUIProps.queryParams.pageSize,
        page: customersUIProps.queryParams.pageNumber,
    };
    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                remote
                                keyField="id"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    customersUIProps.setQueryParams
                                )}
                                // selectRow={getSelectRow({
                                //   entities,
                                //   ids: customersUIProps.ids,
                                //   setIds: customersUIProps.setIds,
                                // })}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
}
