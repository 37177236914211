import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersOpeTable } from "./customers-table/CustomersOpeTable";
import { useLocation } from "react-router-dom";
import Switch from '@material-ui/core/Switch';
/*★★★★★*/
let intervalId = -1;
let refreshFlg = true;
export function CustomersOpeCard() {

  const location = useLocation();
  let title = "";
  if ( location.pathname.match('history') ){
    title="履歴";
  } else {
    title="稼働状況一覧";
  }

  const doReload = () => {
    if ( location.pathname.match('lists') ){
      window.location.reload();
    }
  }

  const [state, setState] = React.useState({
    checkedA: refreshFlg,
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  useEffect(() => {
    if ( state.checkedA == true ) {
      clearInterval(intervalId);
      intervalId = setInterval(() => {
        doReload();
      }, 30000);
      refreshFlg = true;
    } else {
      refreshFlg = false;
      clearInterval(intervalId);
    }
  },[state.checkedA])     

  const refreshSwitch = () => {
    if ( location.pathname.match('lists') ) {
      return (
        <div className="d-flex align-items-center">
          <span className="text-muted ml-5">Auto Refresh</span>
          <Switch
            checked={state.checkedA}
            onChange={handleChange('checkedA')}
            value="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>
      );
    } else {
      return ( <span></span> );
    }
  }

  return (
    <Card>
      <CardHeader title={title}>
        {refreshSwitch()}
      </CardHeader>
      <CardBody>
        <CustomersFilter />
        <CustomersOpeTable />
      </CardBody>
    </Card>
  );
}
