// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import "./img.css"

// const style = {
//   width: 100,
//   height: 120,
//   borderRadius: 4,
// }
const style = {
  width: 100,
  borderRadius: 4,
}

/*★★★★★*/
export function ThumbnailWrokerColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openCustomerFacejudgeDialog, path },
) {

  return (
    <>
      <img 
          src={`data:image/jpeg;base64,${row.worker}`}
          alt=" "
          class="photo_image"
          onClick={() => openCustomerFacejudgeDialog(row.id, path)}
        />
    </>
  );
}
