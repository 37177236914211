import React, { useEffect, useState, Suspense } from "react";
import { Route } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/customers/customersActions";
import { Settings } from "./Settings"
/*★★★★★*/
export function PreSettings({history}) {
  // console.debug("PreSettings");

  const dispatch = useDispatch();
  // const [threshold, setThreshold] = useState(0);
  // useEffect(() => {
  dispatch(actions.loadThreshold()).then((res) => {
      // console.debug(res.facejudge_threshold);
      // setThreshold(res.facejudge_threshold);
      history.push(`/setting/${res.facejudge_threshold}/site`);
    }
  );
  // });

  return (
    <Route path="/setting/:initval/site">
      {/* {({match}) => (
        <Settings
          initval={match.params.initval}
        />
      )} */}
        {({ match }) => (
          <Settings
          initval={match && match.params.initval}
          />
        )}
    </Route>
  );
}
