import React/*, { Suspense, useEffect, useMemo, useState }*/ from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
// import * as actions from "../../../_redux/customers/customersActions";
// import {useCustomersUIContext} from "../CustomersUIContext";
import "./img.css"
const style = {
  width: 125,
  height: 150,
  borderRadius: 2,
}
const judgeOK = "顔認証の結果、データが一致しました";
const judgeNG = "顔認証の結果、データが一致しませんでした";
const simiSentence_s = "(顔認証類似度：";
const simiSentence_e = ")";
const judgeCssOK = `label label-lg label-light-success label-inline h-100`;
const judgeCssNG = `label label-lg label-light-danger label-inline h-100`;
const backcss = `row col-sm-4 align-items-center`;
/*★★★★★*/
export function CustomerFacejudgeForm({ id, faceimage, show, onHide }) {
// console.debug('Judge Dialog')
// console.debug(onHide)
  // Customers Redux state
//   const dispatch = useDispatch();
  const { /*isLoading,*/ entities } = useSelector(
    (state) => ({ 
    //   isLoading: state.customers.actionsLoading,
      entities: state.customers.entities
    }),
    shallowEqual
  );
  // console.debug(id)
  // console.debug(entities)

  const FaceImage = () => (
    <img 
      src={`data:image/jpeg;base64,${faceimage}`}
      alt="Loading..."
      class="photo_face"
    />
  );

  let worker, faceJudge, similarity = "";
  if( entities != null) {
    entities.map(entity => {
      // console.debug(entity);
      if( entity.id == id ) {
        worker = entity.worker
        faceJudge = entity.face_judge;
        similarity = entity.face_judge_similarity;
      }
    });
  }

  const FaceJudge = () => {
    let judge, result = "";
    if( faceJudge == "OK") {
      // console.debug('OK')
      judge = judgeCssOK;
      result = judgeOK;
    } else {
      // console.debug('NG')
      judge = judgeCssNG;
      result = judgeNG;
    }
    return (
      <div className={`col ${judge}`}>
        <div className="row justify-content-center">
          <span align="center">
            {result}
          <br/>
            {simiSentence_s}{similarity}{simiSentence_e}
          </span>
        </div>
      </div>
    );
  }

  return (
      <Modal.Body>
        <div className="form-group row">
          <div className="col-sm-4">
            <div className="justify-content-center">
              <div className="col">
                  <p>プロフィール写真</p>
              </div>
              </div>
          </div>
          <div className="col-sm-4">
          </div>
          <div className="col-sm-4">
            <div className="col">
              <div className="justify-content-center">
                  <p>作業時写真</p>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-4">
            <div className="justify-content-center">
              <div className="col">
                <FaceImage/>
              </div>
            </div>
          </div>
          <div className={backcss}>
            <div className="col">
            <div className="row justify-content-center">
                <p>←　比較　→</p>
            </div>
            </div>
          </div>
          <div className="col-sm-4">
            {/* <div className="justify-content-center"> */}
            <div className="col">
              <img 
                  src={`data:image/jpeg;base64,${worker}`}
                  alt="no photo"
                  class="photo_face"
              />
            </div>
            {/* </div> */}
          </div>
        </div>
        <div className="form-group row">
          <FaceJudge/>
        </div>
      </Modal.Body>
  );
}
