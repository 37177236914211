import React, { useEffect } from "react";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../../_metronic/_partials/controls";
import {HistoryStatusMachineTable} from "./customers-table/HistoryStatusMachineTable";
import {HistoryStatusMachineFilter} from "./customers-filter/HistoryStatusMachineFilter";

export function HistoryStatusMachineCard() {
    let title = "建機の点検結果";

    return (
        <Card>
            <CardHeader title={title}>
            </CardHeader>
            <CardBody>
                <HistoryStatusMachineFilter />
                <HistoryStatusMachineTable />
            </CardBody>
        </Card>
    );
}
