import React from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
/*★★★★★*/
export function Cards() {
    const loc = useLocation();
    let title = "";
    switch (loc.pathname) {
      case "/operation/history":
        title = "履歴"
        break;
      case "/information/admin":
        title = "管理者一覧"
        break;
      case "/information/site":
        title = "現場一覧"
        break;
      case "/information/company":
        title = "会社一覧"
        break;
      case "/information/machine":
        title = "建機一覧"
        break;
      case "/setting/personal":
        title = "個人設定"
        break;
    }

    return (
      <Card>
        <CardHeader title={title}>
          {/* <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={customersUIProps.newCustomerButtonClick}
            >
              Operation Status
            </button>
          </CardHeaderToolbar> */}
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    );
  }
  